import { FocusEvent, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  debounce,
  grid2Classes,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { SearchIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useColorLog, usePathname } from 'src/common/hooks'
import { ELEMENT_HEIGHT, LogColor, UpsertMode } from 'src/common/index'
import { useUserStore } from 'src/common/stores/UserStore'

import { cleanNonDigits, isInputValid } from '../../lib'
import { useBuyerStore, usePurchaserStore } from '../../stores'
import LocationDropDown from '../LocationDropDown'
import { FormInput } from './FormInput'
import LabelInputIcon from './LabelInputIcon.tsx'
import { PasswordField } from './PasswordField'

export default function GeneralFields(): JSX.Element {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const { upsertMode } = usePathname('purchasers')
  const {
    purchaser,
    setMustRefetchPurchaser,
    updatePurchaser,
    locations,
    setOpenFloridaySearch,
    setOpenFloridayBuyerSearch,
  } = usePurchaserStore()

  const {
    buyerNumber,
    buyerName,
    setBuyerName,
    setBuyerNumber,
    setMustRefetchBuyer,
  } = useBuyerStore()

  const colorLog = useColorLog()

  const handleClickOpen = () => {
    setOpenFloridaySearch(true)
  }

  const handleBuyerSelectionOpen = () => {
    setOpenFloridayBuyerSearch(true)
  }

  const handlePurchaserIndicationChange = useCallback(
    (event: any) => {
      const { name, value } = event.target
      if (!isInputValid(name, value)) return
      updatePurchaser({
        [name]: value,
        sourceCode:
          upsertMode === UpsertMode.add ? 'RRU' : purchaser?.sourceCode,
      })
    },
    [purchaser?.sourceCode, updatePurchaser, upsertMode]
  )

  const handleChange = useCallback(
    (event: any) => {
      const { name, value } = event.target
      if (!isInputValid(name, value)) return
      updatePurchaser({ [name]: value })
    },
    [updatePurchaser]
  )

  const handleSeatChange = useCallback(
    (event: any) => {
      const { value } = event.target
      const slicedValue = value.slice(0, 4)
      if (!isInputValid('zetelnummer', slicedValue)) return
      const resValue = slicedValue ? Number(slicedValue) : null
      updatePurchaser({ zetelnummer: resValue })
    },
    [updatePurchaser]
  )

  const handleLocationChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const locatieCode: string = event.target.value
      if (!locatieCode) {
        updatePurchaser({ locatieCode: '', locatieOmschrijving: '' })
      }

      const locatieOmschrijving = locations.find(
        l => l.locatieCode === locatieCode.toUpperCase()
      )?.locatieOmschrijving

      if (locatieOmschrijving) {
        updatePurchaser({ locatieCode, locatieOmschrijving })
      }
    },
    [locations, updatePurchaser]
  )

  const changeBuyerNumber = (value: any) => {
    const newValue = cleanNonDigits(value)
    setBuyerNumber(Number(newValue))
    setMustRefetchBuyer(true)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bouncedNewInput = useCallback(debounce(changeBuyerNumber, 1500), [
    user.accessToken,
  ])

  const handleChangeBuyerNumber = useCallback(
    (event: any) => {
      const { value } = event.target
      if (!isInputValid('koperNummer', value)) return
      bouncedNewInput(value)
    },
    [bouncedNewInput]
  )

  const handleBlurBuyer = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const newValue = Number(event.target.value)
      if (newValue === 0) {
        setBuyerName(null)
      } else if (newValue !== buyerNumber) {
        colorLog(
          LogColor.khaki,
          'GeneralFields',
          'handleBlurBuyer(), [apiClient])',
          'updatePurchaser({koperNummer: Number(newBuyerNumber)})\n' +
            '   => setBuyerNumber(newBuyerNumber)\n' +
            '   => setMustRefetchBuyer(true)'
        )
        updatePurchaser({
          koperNummer: Number(newValue),
        })
        setBuyerNumber(newValue)
        setMustRefetchBuyer(true)
      }
    },
    [
      buyerNumber,
      colorLog,
      setBuyerName,
      setBuyerNumber,
      setMustRefetchBuyer,
      updatePurchaser,
    ]
  )

  const disabled = useMemo(() => upsertMode === UpsertMode.edit, [upsertMode])

  useEffect(() => {
    if (upsertMode === UpsertMode.edit) {
      setMustRefetchPurchaser(true)
    }
  }, [setMustRefetchPurchaser, upsertMode])

  return (
    <Stack gap={ThemeConfig.spacing.s}>
      {/*inkoper identificatie */}
      <FormInput
        label={t('purchaser.purchaserIdentification')}
        name={'inkoperIdentificatie'}
      >
        <TextField
          id='inkoperIdentificatie'
          name='inkoperIdentificatie'
          disabled={disabled || purchaser?.isVervallen}
          value={purchaser?.inkoperIdentificatie || ''}
          onChange={handlePurchaserIndicationChange}
          fullWidth
          slotProps={{
            input: {
              endAdornment: !disabled && (
                <InputAdornment position='end'>
                  <IconButton onClick={handleClickOpen} size='large'>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
            formHelperText: {
              sx: { position: 'absolute', bottom: '-20px' },
            },
          }}
          sx={{ maxHeight: ELEMENT_HEIGHT }}
        />
      </FormInput>
      {/* kopernummer */}
      <LabelInputIcon
        label={t('purchaser.buyerNumber')}
        fieldName={'koperNummer'}
        disabled={disabled || purchaser?.isVervallen}
        onChange={handleChangeBuyerNumber}
        onBlur={handleBlurBuyer}
        inputValue={buyerNumber > 0 ? buyerNumber : ''}
      >
        {disabled &&
          !purchaser?.isVervallen &&
          purchaser?.sourceCode === 'FDY' && (
            <IconButton
              onClick={handleBuyerSelectionOpen}
              sx={{ color: theme.rfhColors.dutchOrange }}
            >
              <SearchIcon />
            </IconButton>
          )}
      </LabelInputIcon>
      {/* kopernaam */}
      <FormInput
        label={t('purchaser.buyerName')}
        name={'koperNaam'}
        value={buyerName}
        disabled
      />
      {/* wachtwoord */}
      <FormInput label={t('purchaser.password')}>
        <PasswordField
          name='wachtwoord'
          value={purchaser?.wachtwoord || ''}
          onChange={handleChange}
          disabled={purchaser?.isVervallen}
          fullWidth
          sx={{
            '& input::-ms-reveal': {
              display: 'none',
            },
            '& input::-ms-clear': {
              display: 'none',
            },
            maxHeight: ELEMENT_HEIGHT,
          }}
        />
      </FormInput>
      {/*Zetel */}
      <FormInput
        label={t('purchaser.seatNumber')}
        name={'zetelnummer'}
        maxLength={4}
        isNumber
        value={purchaser?.zetelnummer > 0 ? purchaser.zetelnummer : ''}
        onChange={handleSeatChange}
        sx={{
          marginTop: '1px',
          [`&>.${grid2Classes.root}[class*="-item"]`]: { marginTop: '-2px' },
        }}
        disabled={purchaser?.isVervallen}
      />
      {/* locatie */}
      <FormInput label={t('common.locationCode')}>
        <LocationDropDown
          value={purchaser?.locatieCode?.toUpperCase() || ''}
          onChange={handleLocationChange}
          name='locatieCode'
          disabled={purchaser?.isVervallen}
        />
      </FormInput>
    </Stack>
  )
}

import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography, useTheme } from '@mui/material'

import { Entity, useFetchOne } from 'src/common/hooks'
import type { IKoper } from 'src/common/services'

type BuyerCardWarningProps = {
  buyerNumber?: number
  otherBuyerNumber?: number
}

const BuyerCardWarning = memo(
  ({ buyerNumber = 0, otherBuyerNumber = 0 }: BuyerCardWarningProps) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const { data: otherBuyer } = useFetchOne<IKoper>(
      {
        key: otherBuyerNumber,
      },
      Entity.IKoper,
      Boolean(
        buyerNumber > 0 &&
          otherBuyerNumber > 0 &&
          otherBuyerNumber !== buyerNumber
      )
    )

    if (otherBuyerNumber === buyerNumber) {
      return null
    }

    return (
      <Box mt={2} mx={3} sx={{ display: 'flex' }}>
        <Typography
          variant='caption'
          sx={{
            color: theme.rfhColors.alertRed,
            marginRight: 1,
            flexShrink: 0,
            alignSelf: 'flex-start',
          }}
        >
          {t('common.note')}
        </Typography>
        <Box>
          <Typography
            variant='caption'
            sx={{
              color: theme.rfhColors.alertRed,
              display: 'block',
              mb: 1,
            }}
          >
            {t('purchaser.purchaserCard.addDialog.linkedToAnotherCustomer')}
          </Typography>
          <Typography
            variant='caption'
            sx={{
              color: theme.rfhColors.alertRed,
              display: 'block',
            }}
          >
            {`${otherBuyerNumber} - ${otherBuyer?.kopernaam}`}
          </Typography>
        </Box>
      </Box>
    )
  }
)

// Add display name for debugging purposes
BuyerCardWarning.displayName = 'OtherBuyerWarning'

export default BuyerCardWarning

import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

import { useApiClient } from 'src/common/providers'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

import { getFuncByEntity } from './own/lib.map'
import { ApiClientAddFuncType, EntityType, FuncSort } from './own/lib.resources'

export const useAddEntity = <T>(
  entityType: EntityType
): UseMutationResult<T, unknown, T, unknown> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(FuncSort.add, entityType) as ApiClientAddFuncType
  const queryClient = useQueryClient()

  const result = useMutation({
    mutationKey: [FuncSort.add, entityType],
    mutationFn: (body: any) => apiClient[func](body) as Promise<T>,
    onSettled: () => {
      queryClient.invalidateQueries()
    },
  })

  if (result.isError) {
    SnackbarUtils.error(`${result.error}`)
  }

  return result
}
